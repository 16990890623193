<template>
  <div>
    <div v-if="isLoading">
      <b-skeleton-table
        :rows="pageSettings.pageSize"
        :columns="columns ? columns.length : 4"
        :table-props="{ outlined: true }"
      />
    </div>

    <ejs-grid
      ref="gridtable"
      :dataSource="data"
      :allowPaging="true"
      :allowResizing="true"
      :pageSettings="pageSettings"
      :allowSorting="true"
      :allowFiltering="true"
      :filterSettings="filterSettings"
      :toolbar="toolbarOptions"
      :showColumnChooser="true"
      :dataStateChange="dataStateChange"
      :queryCellInfo="customizeCell"
      :actionBegin="actionBegin"
    >
      <e-columns>
        <template v-if="columns">
          <e-column
            v-for="column in columns"
            :key="column.field"
            :field="column.field"
            :headerText="column.headerText"
            :textAlign="column.textAlign ? column.textAlign : 'Left'"
            :width="column.width ? column.width : false"
            :type="column.type ? column.type : null"
            :format="column.format !== undefined ? column.format : null"
            :allowFiltering="column.allowFiltering !== undefined ? column.allowFiltering : true"
            :allowSorting="column.allowSorting !== undefined ? column.allowFiltering : true"
            :dataSource="column.dataSource !== undefined ? extData[column.dataSource] : null"
            :foreignKeyValue="column.foreignKeyValue !== undefined ? column.foreignKeyValue : null"
            :foreignKeyField="column.foreignKeyField !== undefined ? column.foreignKeyField : null"
            :disableHtmlEncode="column.disableHtmlEncode !== undefined ? column.disableHtmlEncode : true"
            :filter="column.field === dateFilterColumn ? filterDate : (column.filter !== undefined ? column.filter : {})"
            :template="column.template !== undefined ? column.template : null"
            :valueAccessor="column.valueAccessor !== undefined ? column.valueAccessor : null"
          ></e-column>
        </template>
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>
import Vue from 'vue'
import SmartTableRu from '@/common/SmartTableRu.json'
import { L10n, setCulture, createElement } from '@syncfusion/ej2-base'
import { GridPlugin, Page, Sort, Filter, Toolbar, ColumnChooser, Resize, ForeignKey } from '@syncfusion/ej2-vue-grids'
import { DropDownList } from '@syncfusion/ej2-dropdowns'
import { DatePicker, DateRangePicker } from '@syncfusion/ej2-calendars'

/* import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json'
import * as gregorian from 'cldr-data/main/ru/ca-gregorian.json'
import * as numbers from 'cldr-data/main/ru/numbers.json'
import * as timeZoneNames from 'cldr-data/main/ru/timeZoneNames.json'
import * as weekData from 'cldr-data/supplemental/weekData.json' */
import moment from 'moment'

Vue.use(GridPlugin)
L10n.load(SmartTableRu)
// loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData)
setCulture('en') // change to 'ru'

export default {
  name: 'Table',
  props: {
    sourceUrl: {
      type: String,
      required: true
    },
    extSource: {
      type: Object
    },
    columns: {
      type: Array
    },
    customizeCell: {
      type: Function
    },
    dateFilterColumn: {
      type: String
    }
  },
  data () {
    const filterOptionsDateFilter = [
      {
        text: 'Любая дата',
        value: 'any'
      },
      {
        text: 'Текущий месяц',
        value: 'current_month'
      },
      {
        text: 'Предыдущий месяц',
        value: 'prev_month'
      },
      {
        text: 'Текущий квартал',
        value: 'current_quarter'
      },
      {
        text: 'Диапазон',
        value: 'range'
      }
    ]

    return {
      isLoading: true,
      data: {},
      extData: {},
      pageSettings: {
        pageSizes: [5, 20, 50, 100, 200, 500],
        currentPage: 1,
        pageSize: 20
      },
      filterSettings: {
        type: 'CheckBox'
      },
      toolbarOptions: ['ColumnChooser'],
      wrapSettings: { wrapMode: 'Content' },
      takeRows: 0,
      skipRows: 0,
      dateValueDateFilter: '',
      dateTypeDateFilter: 'any',
      dropInstanceDateFilter: null,
      datePickerInstanceDateFilter: null,
      filterDate: {
        type: 'Menu',
        ui: {
          create: (args) => {
            args.getOptrInstance.dropOptr.element.parentElement.parentElement.style.display = 'none'
            const isFiltered = event.target.classList.contains('e-filtered')
            const operationInput = createElement('input')
            args.target.appendChild(operationInput)
            const valueInput = createElement('input')
            args.target.appendChild(valueInput)

            if (this.dateTypeDateFilter !== 'any' && this.dateTypeDateFilter !== 'range') {
              valueInput.style.display = 'none'
            }

            this.dropInstanceDateFilter = new DropDownList({
              dataSource: filterOptionsDateFilter,
              fields: { text: 'text', value: 'value' },
              value: this.dateTypeDateFilter,
              popupHeight: '200px',
              change: (event) => {
                if (event.value) {
                  this.dateTypeDateFilter = event.value

                  switch (event.value) {
                    case 'any':
                      args.getOptrInstance.dropOptr.value = 'equal'
                      this.destroyDatePicker()

                      valueInput.style.display = 'inline'
                      this.datePickerInstanceDateFilter = this.createDatePicker(isFiltered, this.dateValueDateFilter)
                      this.datePickerInstanceDateFilter.appendTo(valueInput)
                      break

                    case 'current_month':
                      args.getOptrInstance.dropOptr.value = 'greaterthanorequal'
                      this.destroyDatePicker()
                      valueInput.style.display = 'none'
                      break

                    case 'prev_month':
                      args.getOptrInstance.dropOptr.value = 'greaterthanorequal'
                      this.destroyDatePicker()
                      valueInput.style.display = 'none'
                      break

                    case 'current_quarter':
                      args.getOptrInstance.dropOptr.value = 'greaterthanorequal'
                      this.destroyDatePicker()
                      valueInput.style.display = 'none'
                      break

                    case 'range':
                      args.getOptrInstance.dropOptr.value = 'greaterthanorequal'
                      this.destroyDatePicker()

                      valueInput.style.display = 'inline'
                      this.datePickerInstanceDateFilter = this.createDateRangePicker(isFiltered, this.dateValueDateFilter)
                      this.datePickerInstanceDateFilter.appendTo(valueInput)
                      break
                  }
                }
              }
            })

            if (this.dateTypeDateFilter === 'any') {
              this.datePickerInstanceDateFilter = this.createDatePicker(isFiltered, this.dateValueDateFilter)
              this.datePickerInstanceDateFilter.appendTo(valueInput)
            } else if (this.dateTypeDateFilter === 'range') {
              this.datePickerInstanceDateFilter = this.createDateRangePicker(isFiltered, this.dateValueDateFilter)
              this.datePickerInstanceDateFilter.appendTo(valueInput)
            }

            this.dropInstanceDateFilter.appendTo(operationInput)
          },
          write: (args) => {
            this.dropInstanceDateFilter.value = args.filteredValue
          },
          read: (args) => {
            let filterValue = ''

            switch (this.dropInstanceDateFilter.value) {
              case 'any':
                if (this.datePickerInstanceDateFilter.value !== null) {
                  filterValue = moment(this.datePickerInstanceDateFilter.value).format('YYYY-MM-DD')
                }
                break

              case 'current_month':
                filterValue = moment().format('YYYY-MM-01 00:00:00')
                break

              case 'prev_month':
                filterValue = moment().subtract(1, 'months').format('YYYY-MM-01 00:00:00')
                break

              case 'current_quarter':
                filterValue = moment().startOf('quarter').format('YYYY-MM-DD 00:00:00')
                break
            }

            if (filterValue !== '') {
              args.fltrObj.filterByColumn(args.column.field, args.operator, filterValue)
            } else {
              this.$refs.gridtable.clearFiltering([this.dateFilterColumn])
            }
          }
        }
      }
    }
  },
  created () {
    this.prepareDataFromUrl()
  },
  async mounted () {
    await this.getExtData()

    this.dataStateChange({
      action: {},
      skip: this.skipRows,
      take: this.takeRows
    })
  },
  provide: {
    grid: [Page, Sort, Filter, Toolbar, ColumnChooser, Resize, ForeignKey]
  },
  methods: {
    async dataStateChange (state) {
      if (state.action && (state.action.requestType === 'filterchoicerequest' || state.action.requestType === 'filtersearchbegin' || state.action.requestType === 'stringfilterrequest')) {
        this.getData(state).then(e => {
          state.dataSource(e.result)
        })
      } else {
        await this.getData(state).then(gridData => { this.data = gridData })
        this.isLoading = false
      }
    },
    async getData (state) {
      let pageQuery = '?page=1'
      const perPageQuery = state.take ? `&per-page=${state.take}` : `&per-page=${this.pageSettings.pageSize}`
      const sortQuery = this.getSortQuery(state)
      const filterQuery = this.getFilterQuery(state)
      const bFilterRequest = state.action.requestType === 'filterchoicerequest' || state.action.requestType === 'filtersearchbegin' || state.action.requestType === 'stringfilterrequest'

      if (bFilterRequest === false) {
        if (state.action.action !== 'filter' && state.action.action !== 'clear-filter') {
          if (state.action.currentPage !== undefined) {
            pageQuery = `?page=${state.action.currentPage}`
          } else {
            pageQuery = `?page=${this.pageSettings.currentPage}`
          }
        } else {
          this.pageSettings.currentPage = 1
          this.$refs.gridtable.goToPage(this.pageSettings.currentPage)
        }
      }

      const query = pageQuery + perPageQuery + sortQuery + filterQuery

      return await this.$api.query(this.sourceUrl + query).then(response => {
        const data = this.formatDateColumns(response.data)
        const count = response.total

        if (history.pushState && bFilterRequest === false) {
          const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname + (query || '')
          window.history.pushState({ path: newUrl }, '', newUrl)
        }

        return { result: data, count: parseInt(count, 10) }
      })
    },
    async getExtData () {
      for (const key in this.extSource) {
        if (Object.hasOwnProperty.call(this.extSource, key)) {
          const el = this.extSource[key]

          if (typeof el === 'object') {
            this.extData[key] = el
          } else {
            await this.$api.query(el).then(response => {
              this.extData[key] = response.data
            })
          }
        }
      }
    },
    getSortQuery (state) {
      let sortQuery = ''

      if ((state.sorted || []).length) {
        sortQuery = '&sort=' + state.sorted.map(obj => {
          return obj.direction === 'descending' ? `-${obj.name}` : obj.name
        }).reverse().join(',')
      }

      return sortQuery
    },
    getFilterQuery (state) {
      let filterQuery = ''
      const operatorValues = {
        lessthan: '<',
        greaterthan: '>',
        lessthanorequal: '<%3D',
        greaterthanorequal: '>%3D',
        equal: '%3D',
        notequal: '<>'
      }

      if ((state.where || []).length) {
        if (state.where[0].predicates) {
          state.where[0].predicates.forEach(filter => {
            if (filter.predicates) {
              filter.predicates.forEach(subFilter => {
                const subField = subFilter.field
                const subValue = subFilter.value
                const subFieldType = this.$refs.gridtable.getColumnByField(subField).type

                if (subFieldType.indexOf('date') !== -1) {
                  filterQuery += `&filter[${subField}][${operatorValues[subFilter.operator]}]=${subValue}`
                } else {
                  filterQuery += `&filter[${subField}][in]=${subValue}`
                }
              })
            } else {
              const field = filter.field
              const value = filter.value
              const fieldType = this.$refs.gridtable.getColumnByField(field).type

              if (fieldType.indexOf('date') !== -1) {
                filterQuery += `&filter[${field}][${operatorValues[filter.operator]}]=${value}`
              } else {
                filterQuery += `&filter[${field}]=${value}`
              }
            }
          })
        } else {
          const field = state.where[0].field
          const value = state.where[0].value

          filterQuery += `&filter[${field}]=${value}`
        }
      }

      return filterQuery
    },
    formatDateColumns (data) {
      if (data !== undefined) {
        const dateColumns = this.columns.filter(el => {
          if (el.type !== undefined) {
            if ('date'.indexOf(el.type) !== -1) {
              return el.field
            }
          }
        })

        if (dateColumns.length) {
          dateColumns.forEach(column => {
            data.forEach(el => {
              el[column.field] = new Date(el[column.field])
            })
          })
        }
      }

      return data
    },
    actionBegin (args) {
      if (args.requestType === 'filtering') {
        if (args.currentFilteringColumn === this.dateFilterColumn) {
          const indexCustomColumn = args.columns.findIndex(el => el.customColumn === true)
          if (indexCustomColumn !== -1) {
            args.columns.splice(indexCustomColumn, 1) // remove previous custom filter value
          }

          if (args.currentFilterObject.operator === 'greaterthanorequal') {
            let filterValue = null
            let date = ''

            switch (this.dateTypeDateFilter) {
              case 'current_month':
                date = moment()
                filterValue = date.format(`YYYY-MM-${date.daysInMonth()} 23:59:59`) // last date of current month
                break

              case 'prev_month':
                date = moment(args.currentFilterObject.value)
                filterValue = date.format(`YYYY-MM-${date.daysInMonth()} 23:59:59`)
                break

              case 'current_quarter':
                date = moment()
                filterValue = moment().endOf('quarter').format('YYYY-MM-DD 23:59:59')
                break

              case 'range':
                date = moment(this.dateValueDateFilter[1])
                filterValue = date.format('YYYY-MM-DD 23:59:59')
                break
            }

            if (filterValue) {
              args.columns.push({
                customColumn: true,
                actualFilterValue: {},
                actualOperator: {},
                field: args.currentFilteringColumn,
                ignoreAccent: false,
                isForeignKey: false,
                matchCase: false,
                operator: 'lessthanorequal',
                predicate: 'and',
                uid: this.$refs.gridtable.getColumnByField(
                  args.currentFilteringColumn
                ).uid,
                value: filterValue
              })
            }
          }
        }
      }
    },
    createDatePicker (isFiltered = false, value = '') {
      return new DatePicker({
        placeholder: 'Выберите дату',
        value: isFiltered ? value : null,
        format: 'dd.MM.yyyy',
        change: (event) => {
          if (event.value) {
            this.dateValueDateFilter = event.value
          }
        }
      })
    },
    createDateRangePicker (isFiltered = false, value = '') {
      return new DateRangePicker({
        placeholder: 'Выберите даты',
        value: isFiltered ? value : null,
        format: 'dd.MM.yyyy',
        change: (event) => {
          if (event.value) {
            this.dateValueDateFilter = event.value
            this.$refs.gridtable.filterByColumn(
              this.dateFilterColumn,
              'greaterthanorequal',
              moment(this.dateValueDateFilter[0]).format('YYYY-MM-DD 00:00:00')
            )
          } else {
            this.$refs.gridtable.clearFiltering([this.dateFilterColumn])
          }
        }
      })
    },
    destroyDatePicker () {
      if (this.datePickerInstanceDateFilter.isDestroyed !== true) {
        this.datePickerInstanceDateFilter.destroy()
        this.dateValueDateFilter = ''
      }
    },
    prepareDataFromUrl () {
      this.takeRows = this.pageSettings.pageSize

      if (this.$route.query['per-page'] !== undefined) {
        this.pageSettings.pageSize = parseInt(this.$route.query['per-page'], 10)
        this.takeRows = parseInt(this.$route.query['per-page'], 10)
      }

      if (this.$route.query.page !== undefined) {
        this.pageSettings.currentPage = parseInt(this.$route.query.page, 10)
        this.skipRows = (parseInt(this.$route.query.page) - 1) * this.$route.query['per-page'] ? parseInt(this.$route.query['per-page'], 10) : this.pageSettings.pageSize
      }
    }
  }
}
</script>

<style>
@import '../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';

.e-excel-ascending,
.e-excel-descending,
.e-separator.e-excel-separator {
  display: none;
}

.e-grid.e-responsive .e-rowcell {
  text-overflow: initial;
  white-space: initial;
}
</style>
